@import "../../assets/main.scss";

.footer {
  text-align: center;
  padding: 5%;
  color: $black;
  background: $whitesmoke;
  border-radius: 20px;

  .content {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

    .download {
      color: $black;
      border-radius: 5px;
      padding: 10px 15px;
      text-decoration: none;
      background: rgba(128, 128, 128, 0.176);

      &:hover {
        background: $maincolor;
        color: $white;
        border-color: $maincolor;
        transition: background 0.2s ease;
      }
    }
  }
}
