@import "../../assets/main.scss";

.themeToggler {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: rgba(128, 128, 128, 0.176);
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background: $maincolor;
    color: $white;
    border-color: $maincolor;
    transition: background 0.2s ease;
  }
}

@media screen and (min-width: $firstbreakpoint) {
  .theme-toggler {
    .toggle {
      width: 60px;
      height: 30px;

      .round {
        width: 30px;
        height: 30px;
      }
    }
  }
}
