@import "../../assets/main.scss";

.workcard {
  text-decoration: none;
  border-radius: 15px;
  margin: 2% 2%;
  padding: 5%;
  width: 100%;
  height: max-content;
  background: $grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: slidein 1s ease both;

  &:hover {
    outline: 2px solid $shadow;
    transition: outline 0.1s ease;
  }

  .workplace {
    width: 100%;
    margin: 5% auto;

    h3 {
      color: $black;
    }

    p {
      color: $black;
      opacity: 0.8;
    }

    .about {
      font-weight: 400;
      color: $black;
    }

    .tags {
      text-transform: capitalize;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5%;
      margin: 5% auto;

      span {
        font-family: "Mulish", sans-serif;
        font-weight: 400;
        background: $tags;
        color: $black;
        padding: 2% 3%;
        border-radius: 5px;
        margin: 1% 0%;
      }
    }
  }

  svg {
    display: block;
    margin: 0 0 0 auto;
    color: $maincolor;
    font-size: 30px;
  }
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translateY(5vh); /* Slide in from below */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.maislider {
  margin: 0 auto 100px auto;
  padding: 30px 0;

  h2 {
    text-transform: none;
    margin-bottom: 50px;
    color: $maincolor;
  }

  .slider {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
}

@media screen and (min-width: $firstbreakpoint) {
  .workcard {
    width: 42%;
    margin: 1%;
    padding: 0% 2%;
  }
}
