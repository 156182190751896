@import "../../assets/main.scss";

header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5% 5%;

  a {
    color: $maincolor;
    text-decoration: none;
    font-weight: 600;
    padding: 1% 2%;
    border-radius: 15px;

    &:hover {
      
      color: $black;
    }

 
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .notfortel {
    display: none;
  }

  .navs {
    width: 50%;
    display: flex;
    gap: 2%;
    justify-content: flex-end;
  }
}

@media screen and (min-width: $firstbreakpoint) {
  header {
    padding: 1% 5%;

    .notfortel {
      display: block;
    }
  }
}
