@import "../../assets/main.scss";

.SocialButtons {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }

  .linkbutton {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4%;
    margin: 1% 1% 1% 0;
    border-radius: 25px;
    background: $grey;
    border: 1px solid $grey;
    color: $maincolor;
    gap: 2%;
    transition: all 0.2s ease;

    &:hover {
      outline: 2px solid $shadow;
      transition: outline 0.1s ease;
    }

    .linkname {
      display: none;
    }
  }
}

@media screen and (min-width: $firstbreakpoint) {
  .SocialButtons {
    .linkbutton {
      border-radius: 40px;
      padding: 2%;
      .linkname {
        display: block;
      }
    }
  }
}
