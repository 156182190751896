:root {
  --maincolor: #0a6ebd;
  --black: #101010;
  --white: #ffffff;
  --whitesmoke: whitesmoke;
  --shadow: rgba(0, 0, 0, 0.19);

  --tags: #f6f6f6;
  --grey: #00000005;
  --imgFilter: hue-rotate(230deg) brightness(0);
}

.dark {
  --maincolor: #0a6ebd;
  --black: #e4e4e4;
  --white: #000000;
  --whitesmoke: #131313;
  --shadow: rgba(255, 255, 255, 0.19);
  --tags: #2f2f2f8a;
  --grey: #1d1c20;
  --imgFilter: brightness(0) invert(1);
}

$firstbreakpoint: 1100px;
$maincolor: var(--maincolor);
$mainglacy: var(--mainglacy);
$white: var(--white);
$tags: var(--tags);
$whitesmoke: var(--whitesmoke);
$black: var(--black);
$shadow: var(--shadow);
$grey: var(--grey);
$imageFilter: var(--imgFilter);

//fonts
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.darkhtml {
  filter: invert(100%) hue-rotate(180deg);
}

body {
  background: $white;
  font-family: "Mulish", sans-serif;
  margin: 0 auto;
}

h1 {
  font-weight: 600;
  margin: 0%;
}

h2 {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0%;
}

h3 {
  font-weight: 550;
}

p {
  font-weight: 500;
}

p,
span,
a,
ul,
li,
details,
summary,
input,
label,
button {
  font-family: "Mulish", sans-serif;
  line-height: 150%;
}

h1,
h4,
h5,
span,
p {
  margin: 0%;
}

main {
  width: 1200px;
  margin: 1% auto;
}

@media screen and (max-width: $firstbreakpoint) {
  main {
    width: 90%;
    padding: 5%;
  }
}
