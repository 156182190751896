@import "../../assets/main.scss";

.home {
  .heroSection {
    padding: 30px 10px;
    .myName {
      display: flex;
      justify-content: space-around;

      h1 {
        color: $maincolor;
        font-size: 3rem;
        font-weight: 900;
        padding: 5px 0 0 0;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .positions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2%;
      margin-bottom: 10px;

      .my-position {
        color: $black;
        font-size: 1rem;
        letter-spacing: 0.1rem;

        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (min-width: $firstbreakpoint) {
  .home {
    .heroSection {
      padding: 20px 10px;

      .myName {
        h1 {
          font-size: 8rem;
        }
      }

      .positions {
        .my-position {
          font-size: 1.5rem;
          letter-spacing: 0.7rem;
        }
      }
    }
  }
}
